import Checkbox from "@index/components/common/checkbox/checkbox";
import Button from "@index/components/common/gui/button/button";
import setMask from "@index/ext/helpers/string/setMask";
import ContactInformation from "./contact-information/contact-information";
import PersonalInformation from "./personal-information/personal-information";
import PassportInformation from "./passport-information/passport-information";
import Card from "./card/card";
import Code from "./code/code";
import Iframe3ds from "./iframe3ds/iframe3ds";
import loader from "@index/ext/helpers/loader";
import AnketaMixin from "@frontend/fin-recurrent-main/mixins/anketa.js";

export default {
  components: {
    ContactInformation,
    PersonalInformation,
    PassportInformation,
    Card,
    Checkbox,
    Button,
    Code,
    Iframe3ds,
  },
  mixins: [AnketaMixin],
  data() {
    return {
      isSubmit: false,
    };
  },
  computed: {
    workflow() {
      return ((this.$route || {}).meta || {}).workflow || "direct";
    },
  },
  watch: {
    isRequest(isRequest) {
      if (isRequest) {
        loader.on();
      } else {
        loader.off();
      }
    },
  },
  mounted() {
    this.isRequest = true;

    if (this.$route.params.step === "back") {
      this.state.token = this.tokenForm;
      this.state.step = this.stepForm;
      this.onBack();
      return;
    }
    this.reInit();
  },
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0);
    if (!to.params.step) {
      next({ path: to.path + "/init" });
    }

    next();
  },
  beforeRouteUpdate(to, from, next) {
    window.scrollTo(0, 0);
    if (to.params.step && this.state.step !== to.params.step) {
      this.onBack();
    }

    if (!to.params.step) {
      next({ path: to.path + "/init" });
    }

    next();
  },
  methods: {
    checkRouterAndGoNext() {
      if (this.state.step !== this.$route.params.step) {
        this.$router.push({
          path:
            this.$route.path.replace(this.$route.params.step, "") +
            this.state.step,
        });
      }
    },
    reInit() {
      if (this.tokenForm) {
        this.$infra.finvin
          .show({
            token: this.tokenForm,
          })
          .then((res) => {
            if (res.token) {
              this._setGeneralState(res);
              this.checkboxesChecked();
              return;
            }

            throw res;
          })
          .catch(() => {
            this.init();
          });
      } else {
        this.init();
      }
    },
    init() {
      this.$infra.finvin
        .init(this.workflow)
        .then((res) => {
          this._setGeneralState(res);
          this.checkboxesChecked();
        })
        .catch((e) => {
          this.res = e;
        });
    },
    submitPhoneEmail() {
      this.isSubmit = true;
      this.$refs.contactInformation.validate();
      const isValid = this.$refs.contactInformation.isValid;
      const isValidCheckbox = (this.state.checkboxes || []).filter(i=>i.required && this.checked.includes(i.name)).length;

      if (isValidCheckbox && isValid) {
        const { phone, email } = this.$refs.contactInformation.form;

        const params = {
          phone: phone.replace(/[-,(,)]/g, ""),
          ...this.checked.reduce((a, k)=>{a[k] = true; return a;}, {})
        };

        if (email) {
          // тк бэкенд не умеет сам удалять пустые значения
          params.email = email;
        }

        this._fetchStep(params).then(() => {
          this._ymReachGoal("finvinstep1");
          this.$infra.tracker.step1submitForm()
        });
      }
    },
    submitAccountStep() {
      this.isSubmit = true;
      this.$refs.passportInformation.validate();
      this.$refs.personalInformation.validate();
      const isValid =
        this.$refs.passportInformation.isValid &&
        this.$refs.personalInformation.isValid;

      if (isValid) {
        const data = {
          ...this.$refs.passportInformation.form.contactData,
          ...this.$refs.personalInformation.form,
          ...this.$refs.passportInformation.form.passportData,
          passportissuecode: setMask(
            this.$refs.passportInformation.form.passportData
              .passportissuecode,
            "###-###"
          ),
        };

        const params = {
          firstname: data.firstname,
          lastname: data.lastname,
          passport_serie: data.passportseries,
          passport_number: data.passportnumber,
          passport_issuecode: data.passportissuecode,
          birthday: data.birthday,
          address: data.addrcity,
          timeZone:
            window.Intl.DateTimeFormat().resolvedOptions().timeZone,
          meta: [],
          passport_issuedate: data.passportissuedate,
          passport_issuer: data.passportissuer,
        };

        if (data.patronymic) {
          // тк бэкенд не умеет сам удалять пустые значения
          params.patronymic = data.patronymic;
        }

        this._fetchStep(params).then(() => {
          this._ymReachGoal("finvinstep2");
          this.$infra.tracker.step2submitPassport()
        });
      }
    },
    submitCardDataStep() {
      const params = {
        cardNumber: (this.$refs.card.form.number || "").replace(/ /g, ""),
        cardExpiry: this.$refs.card.form.date,
        cardHolder: this.$refs.card.form.holder_name,
        cardCvv: this.$refs.card.form.cvv,
      };
      console.log(params);
      this.$refs.card.validate();
      if (this.$refs.card.isValid) {
        this._fetchStep(params).then(() => {
          this._ymReachGoal("finvinstep3");
          this.$infra.tracker.step4submitCheckCard();
        });
      }
    },
    submitCheckConfirmationStep() {
      this._fetchStep({
        code: this.$refs.code.form.code,
      }).then(() => {
        this._ymReachGoal("finvinstep4");
        this.$infra.tracker.step3submitCheckCode()
      });
    },
    requestApiSendSignConfirmation() {
      const confirmation =
        ((this.state || {}).data || {}).confirmation || {};
      return this.$infra.finvin
        .step({
          token: confirmation.resend_token,
          step: "SendConfirmationStep",
          recaptchaToken: "test",
        })
        .then((res) => {
          if (!res.token) {
            throw res;
          }

          this._setGeneralState(res);
          return res;
        })
        .catch((e) => {
          this.isRequest = false;
          console.error(e);
          alert(e.message || JSON.stringify(e));

          throw e;
        });
    },
    checkboxesChecked() {
      if (this.state.checkboxes) {
        this.state.checkboxes.forEach((item, index) => {
          item.checked ? this.checked.push(index) : "";
        });
      }
  
    }
  },
};
